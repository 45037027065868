import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import { useDispatch } from 'react-redux';
import axios from '../api/axios';
import 'rc-slider/assets/index.css';
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';
import FollowerAdBox from '../components/FollowerAllAdBox';
function AllFollowing(props) {
    const token = JSON.parse(localStorage.getItem('token'));
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const { id } = useParams();
    useEffect(() => {
        getFollowingSingleData();
    }, []);
    const getFollowingSingleData = () => {
        dispatch(startLoading());
        const config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        };
        const bodyParameters = {

            pageName: id
        };
        axios
            .post("/following/getFollowingSingleData", bodyParameters, config)
            .then((res) => {
                setData(res?.data?.data);
                dispatch(stopLoading());
            })
            .catch((err) => {
                dispatch(stopLoading());
            });
    };

    return (
        <>
            <DashboarderHeader />
            <div className="MainContainer">
                    <DashboarderSidebar />
                    <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
                    <main className="content-wrapper">
                            <FollowerAdBox
                                pageName={id}
                                records={data}
                            />
                        </main>
                    </div>


        </>
    )
}

export default AllFollowing