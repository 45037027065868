var path = window.location.origin; // (or whatever)
export const app_base_url = path + '/';

const CryptoJS = require('crypto-js');
export const hashKey = () => {
  const hashKey = 'oppty';
  return hashKey;
};

export const encryptData = keyword =>
  CryptoJS.AES.encrypt(JSON.stringify(keyword), hashKey()).toString().replace(/\//g, '*');

export const decryptData = keyword => {
  const rawText = keyword.replace(/\*/g, '/');
  const bytes = CryptoJS.AES.decrypt(rawText, hashKey());
  const decrypt = bytes.toString(CryptoJS.enc.Utf8);
  const decryptedData = Number(decrypt);

  return decryptedData;
};


export const decryptDataString = keyword => {
  const rawText = keyword.replace(/\*/g, '/');
  const bytes = CryptoJS.AES.decrypt(rawText, hashKey());
  const decrypt = bytes.toString(CryptoJS.enc.Utf8);

  // Remove quotes from the start and end of the decrypted string, if present
  const cleanedDecrypt = decrypt.replace(/^"|"$/g, '');

  // If you expect the decrypted value to be a number, convert it
  const finalValue = isNaN(cleanedDecrypt) ? cleanedDecrypt : Number(cleanedDecrypt);

  return finalValue;
};


export const decryptDataString11 = keyword => {
  const rawText = keyword.replace(/\*/g, '/');
  const bytes = CryptoJS.AES.decrypt(rawText, hashKey());
  const decrypt = bytes.toString(CryptoJS.enc.Utf8);
  // const decryptedData = Number(decrypt);

  return decrypt;
};

// export const dateFromMillisecond = milliseconds => {

//   /*  var date = second;
//    var d = new Date(parseInt(date, 10));
//    var ds = d.toString('MM/dd/yy HH:mm:ss');
//    return ds; */

//   const rawDate = new Date(milliseconds)

//   const date = new Date(parseInt(milliseconds));
//   const formattedDate = date.toLocaleString();
//   return formattedDate;
// };
// export const dateFromMillisecond = milliseconds => {
//   const unixTime = milliseconds;
//   const date = new Date(unixTime * 1000); // Multiply by 1000 as JavaScript works with milliseconds
//   const formattedDate = date.toDateString() + " " + date.toLocaleTimeString();

//   return formattedDate;
// }
export const dateFromMillisecond = (milliseconds) => {
  const unixTime = milliseconds;
  const date = new Date(unixTime * 1000); // Multiply by 1000 as JavaScript works with milliseconds
  const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Remove seconds
  const formattedDate = date.toDateString() + " " + formattedTime;

  return formattedDate;
};


