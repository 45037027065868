import { MY_AD } from '../actions/actionsTypes';

const initialState = {
    ads: [],
    filter: '',
};

export default function(state = initialState, action)  {
  

    switch (action.type) {
        
        case MY_AD:
            
            return {...state,ads:action.payload}
      
        default:
            return state;
    }
}

