import React, { useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FollowerAdBoxCardItem from "./FollowerAdBoxCardItem";
import FollowerAdBoxCardCategory from "./FollowerAdBoxCardCategory";
function FollowerAdBox(props) {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <>

            <div className='following-companyName d-flex justify-content-between flex-wrap'>
                {(() => {
                    const record = JSON.parse(props?.records[0]?.record);

                    return (
                        <FollowerAdBoxCardCategory

                            item={props?.item}
                            records={props?.records}
                        />
                    )
                })()}
            </div>

            <div className='row mb-3'>

                {props.records.slice(0, 4).map((ad) => {
                    const record = JSON.parse(ad?.record);
                    return (
                        <FollowerAdBoxCardItem
                            key={ad.id} // Add a unique key if `ad.id` or equivalent is available
                            ad={ad}
                            record={record}
                        />
                    );
                })}



            </div>

        </>
    )
}

export default FollowerAdBox