import React, { useState, useEffect } from 'react';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import axios from '../api/axios';
import ReactPaginate from 'react-paginate';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';

function PlanHistory() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const user_id = localStorage.getItem('user_id');
  const token = JSON.parse(localStorage.getItem('token'));

  useEffect(() => {
    getPlanHistory(currentPage);
  }, [currentPage]);

  const getPlanHistory = (page) => {
    
    const config = {
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    };

    axios
      .post('/plan-history', { id: user_id, page }, config)
      .then((res) => {
        setData(res.data.data.data); 
        setTotalPages(res.data.data.last_page); 
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1); 
  };

  return (
    <>
      <DashboarderHeader />
      <div className="MainContainer">
        <DashboarderSidebar />
        <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
        <main className="content-wrapper">
          <div className="profile-div" style={{width: "100%", margin: "0px"}}>
            <div className="login-inner affilate-link-div" style={{ padding: '30px' }}>
              <h2>Plan History</h2>
              <div className='table-responsive'>
              <table className="table">
                <thead>
                  <tr>
                    <th>SR No</th>
                    <th>Subscription ID</th>
                    <th>Plan Name</th>
                    <th>Price</th>
                    <th>Interval</th>
                    <th>Payment Status</th>
                    <th>Subscription Status</th>
                    <th>Subscribed Date</th>
                    <th>Canceled Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={item.id}>
                        <td>{(currentPage - 1) * 10 + index + 1}</td> 
                        <td>{item.stripe_subscription_id}</td> 
                        <td>{item.plan.name}</td>
                        <td>${item.plan.price}</td> 
                        <td>{item.plan.interval}</td> 
                        <td>{item.payment_status}</td> 
                        <td>
                          {(() => {
                            switch (item.active_plan_status) {
                              case 0:
                                return 'Pending';
                              case 1:
                                return 'Active';
                              case 2:
                                return 'Cancel in progress';
                              case 3:
                                return 'Cancelled';
                              default:
                                return 'Unknown';
                            }
                          })()}
                        </td>
                        <td>{item.created_at ? new Date(item.created_at).toLocaleDateString() : 'N/A'}</td> 
                        <td>{item.unsubscribe_at ? new Date(item.unsubscribe_at).toLocaleDateString() : 'N/A'}</td> 

                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default PlanHistory;
