import { combineReducers } from 'redux';
import adReducer from "./reducers/adReducer";
import myAdReducer from "./reducers/myAdReducer";
import loadingReducer from "./reducers/loadingReducer";
import followedAdReducer from './reducers/followedAdReducer';
//console.log('productReducers');
const rootReducer = combineReducers({
	ad: adReducer,
	myAd:myAdReducer,
	loadingStatus:loadingReducer,
	followedAds: followedAdReducer
	
})

export default rootReducer;



