import React, { useState, useEffect } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import Blogo from '../images/black-logo.png';
import Pro from '../images/pro.png';
import Sponser from '../images/sponsered.png';
import Simg from '../images/s-img.png';
import Fb from '../images/fb.png';
import Filter from '../images/filter.png';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import FacebookAdBox from '../components/FacebookAdBox';
import axios from '../api/axios';
import { toast } from "react-toastify";
import { useSelector, useDispatch } from 'react-redux';
import { myAd } from '../redux/store/actions/adActions';
import { checkImpression } from '../config/fbAdImpression';
import AdFilter from '../components/AdFilter';
import PlanSubscribeChecker from '../components/PlanSubscribeChecker';
function MyAds() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterArr = [];

  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);

  const [error, setError] = useState(null);
  const [selected, setSelected] = useState([]);
  const [revanueValue, setRevanueValue] = useState(0); // Initial value of the slider


  const token = JSON.parse(localStorage.getItem('token'));
  const user_id = JSON.parse(localStorage.getItem('user_id'));

 

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {

    dispatch(myAd());
  }, [dispatch]);
  const products = useSelector((state) => state.myAd);
  const loading = useSelector((state) =>  state.loadingStatus);



  const handleRevanueChange = (newValue) => {

    setRevanueValue(newValue);
  };


  function userPageName(username) {
    if (selected.length > 0) {
      return selected.some(function (el) {
        //  console.log('el.pageName', el);
        return el.label === username;
      });
    } else {
      return false;
    }
  }


  return (
    <>

    <PlanSubscribeChecker />
      <DashboarderHeader />
        <div className="MainContainer">
          <DashboarderSidebar />
          <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>

            <main className="content-wrapper">
            {products.ads.data && products.ads.data.length > 0 && (
          <AdFilter
          filterArr={filterArr}
          setSelected={setSelected}
          selected={selected}
          handleRevanueChange={handleRevanueChange}
          revanueValue={revanueValue}
        />
        )}
             
      
  
    {products.ads.data && products.ads.data.length > 0 && (
      <div className="row">
      {products.ads.data.map((item, i) => {
        const record = JSON.parse(item.record);
        const arr = {
          label: record.pageName || 'Default',
          value: item.id,
        };
        filterArr.push(arr);

        const impressionData = [];
        impressionData['view'] = 0;
        impressionData['spend'] = 0;
        impressionData['day'] = 0;

        if (
          (userPageName(record.pageName) === true || selected.length === 0) &&
          revanueValue === impressionData['spend']
        ) {
          return (
            <FacebookAdBox
              key={i}
              item={item}
              revanueValue={revanueValue}
              pageName="myAds"
              followedPages={products?.ads?.followed_pages}
            />
          );
        }
      })}
      </div>
    ) 
    }
      { products?.ads?.data?.length == 0 &&
      <div className="no-data-container text-center">
        <div>
          <img src="/noAd.png" alt="No Data" className="no-data-image" />
          <h3 className="mt-3">No Ads Yet</h3>
          <div className="button-group godashboard-btn">
            <button className="greenColor-btn me-3" onClick={() => navigate('/dashboard')}>
              Go to Dashboard
            </button>
            <button
              className="outline-greenColor-btn"
              onClick={() =>
                (window.location.href =
                  'https://www.facebook.com/ads/library/?active_status=active&ad_type=political_and_issue_ads&country=IN&is_targeted_country=false&media_type=all')
              }
            >
              Go to Facebook Ad Library
            </button>
          </div>
        </div>
      </div>
    }
  
</main>

          </div>



    </>
  )
}

export default MyAds