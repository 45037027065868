import React, { useState, useEffect } from 'react';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import FollowerAdBox from '../components/FollowerAdBox';
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';
import PlanSubscribeChecker from '../components/PlanSubscribeChecker';
import { followedAd } from '../redux/store/actions/adActions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
function Following() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(followedAd());
  }, [dispatch]);

  const data = useSelector((state) => state.followedAds);
  
  return (
    <>
      <PlanSubscribeChecker />
      <DashboarderHeader />
      <div className="MainContainer">
          <DashboarderSidebar />
          <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
          <main className="content-wrapper">
           
              {(data?.FollowedAds && Object.keys(data?.FollowedAds).length > 0) &&
                Object.keys(data?.FollowedAds ).map(item => (
                  <>
                    <FollowerAdBox
                      item={item}
                      records={data?.FollowedAds[item]}
                    />
                  </>
                ))
              }
              {data?.FollowedAds?.length == 0 &&
                <div className="no-data-container text-center">
                <div>
                <img src="/noAd.png" alt="No Data" className="no-data-image" />
                <h3 className='mt-3'>No Ads Yet</h3>
                {/* <p>It looks like you don’t have any ads to display.</p> */}
                <div className="button-group godashboard-btn">
                <button className="greenColor-btn me-3" onClick={() => navigate('/dashboard')}>
                  Go to Dashboard
                </button>
                  <button
                    className="outline-greenColor-btn"
                    onClick={() =>
                      window.location.href = 'https://www.facebook.com/ads/library/?active_status=active&ad_type=political_and_issue_ads&country=IN&is_targeted_country=false&media_type=all'
                    }
                  >
                    Go to Facebook Ad Library
                  </button>

                </div>
                </div>
              </div>
              }
            </main>
          </div>
    </>
  )
}

export default Following