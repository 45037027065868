import React, { useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { dateFromMillisecond } from '../config/Configuration';
import blackCircle from '../images/blackCircle.jpg';
import { Image } from 'react-bootstrap';
import axios from '../api/axios';
import { toast } from 'react-toastify';
import { followedAd } from '../redux/store/actions/adActions';
import { useDispatch } from 'react-redux';
import { startLoading } from '../redux/store/actions/actionsTypes';
function FollowerAdBoxCardCategory(props) {
   
    const dispatch = useDispatch();
    const token = JSON.parse(localStorage.getItem('token'));
    const [brandImage, setBrandImage] = useState(false);
    useEffect(() => {
        loadDefauldMedia();
    }, []);

    const loadDefauldMedia = () => {
        let tempBrandImg = '';
        let record = JSON.parse(props.records[0].record);
       
        if (props.records[0].ad_logo) {
            tempBrandImg = process.env.REACT_APP_BACKEND_BASE_URL+'/' + props.records[0].ad_logo;

        }
        else if (record?.snapshot?.page_profile_picture_url) {
            tempBrandImg = record.snapshot.page_profile_picture_url;

        }
     
        setBrandImage(tempBrandImg);

       
    };
    const onErrorBrandImage = () => {
        setBrandImage(blackCircle);
    };
    const handleFollowToggle = (pageName) => {
        
        dispatch(startLoading());
        axios
            .post('/toggle-follow', { page_name: pageName }, { headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
             
                if (response.data.status) {
                    toast.success(response.data.message);
                    setTimeout(
                        function () {
                            dispatch(followedAd());
                        }
                            .bind(this),
                        3000
                    );

                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(err => {
                toast.error(err.message || "An error occurred. Please try again.");
            });
    };
    return (
        <>
        <div className='d-flex gap-lg-5 gap-md-3 gap-sm-3 align-items-center flex-wrap gap-2 followingheading-Header'>
                            <div className='d-flex gap-2 align-items-center'>
                                <Image src={brandImage}  onError={() => onErrorBrandImage()} alt='img' style={{ width: "50px", borderRadius: "50%", height: "50px", objectFit: "contain" }} />
                                <h3 className='mb-0' style={{ color: "#333333", fontSize: "22px", fontWeight: "400" }}>{props?.item}</h3>
                            </div>
                            <div className='d-flex gap-1 flex-wrap'>
                                <p className='mb-0' style={{ color: "#333" }}>{props?.records?.length} new ad  |</p>
                                <p className='mb-0' style={{ color: "#333" }}> {dateFromMillisecond(props?.records[0]?.startDate)}</p>
                            </div>
                        </div>
                        <div className='viewunfollow-btn d-flex gap-2 align-items-center'>
                            <button className='outline-greenColor-btn'   onClick={() =>
                          window.location.href = `/all-following/${props?.records[0]?.pageName}`
                        }>View More</button>
                            <button className='greenColor-btn'  onClick={() => handleFollowToggle(props?.item)}>Unfollow</button>
                        </div>
        </>
    )
}

export default FollowerAdBoxCardCategory