import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Blogo from '../images/black-logo.png';
import Pro from '../images/pro.png';
import D1 from '../images/d1.svg';
import D2 from '../images/d2.svg';
import D3 from '../images/d3.svg';
import D4 from '../images/d4.svg';
import Logout from '../images/logout.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal, Button } from 'react-bootstrap';

import Commission from '../images/commission.png';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import axios from '../api/axios';

import { loadStripe } from '@stripe/stripe-js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decryptDataString, decryptData, app_base_url } from "../config/Configuration";
import { startLoading, stopLoading } from '../redux/store/actions/actionsTypes';

function SuccessPayment() {

  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [lifeTimeData, setLifeTimeData] = useState([]);
  const [error, setError] = useState(null);

  const [user_email, setUserLoggedin] = useState(null);
  const [users_name, setUserLoggedName] = useState(null);


  const [datasetId, setDatasetId] = useState(null);
  const [datasetToken, setDatasetToken] = useState(null);
  const [datasetPrice, setDatasetPrice] = useState(null);
  const [datasetUser, setDatasetUser] = useState(null);
  const [datasetName, setDatasetName] = useState(null);
  const [datasetInterval, setDatasetInterval] = useState(null);



  const [affilateModelStatus, setAffilateModelStatus] = useState(false);

  const [affilateText, setAffilateText] = useState(null);

  const [subscribeStatus, setSubscribeStatus] = useState(false);
  const [subscribeData, setSubscribeData] = useState([]);


  const token = JSON.parse(localStorage.getItem('token'));
  const user_id = localStorage.getItem('user_id');


  useEffect(() => {

    const queryParams = new URLSearchParams(window.location.search);

    const status = queryParams.get("status");
    const subscriptionPaymentId = queryParams.get("subscriptionPaymentId");
    const session_id = queryParams.get("session_id");
    if (status == 'true' && subscriptionPaymentId != null && subscriptionPaymentId > 0) {
      //   alert(subscriptionPaymentId);
      updatePaymentStatus(status, subscriptionPaymentId,session_id);
     
    }

    // callbackDropData();
    /*   const data =  getAdsLibraryData('shopify');
      console.log('adsData',data); */

  }, []);



  const updatePaymentStatus = async (status, id,session_id) => {


    const config = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    };

    const bodyParameters = {
      id: id,
      status: status,
      stripe_session_id:session_id
    };
    await axios
      //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
      .post("/pricing/updatePaymentStatus", bodyParameters, config)
      .then((res) => {
        console.log('res.result', res.data);


        //   setLoader(false);
      })
      .catch((err) => {
        //   setLoader(false);
      });
  };


  return (
    <>
      <DashboarderHeader />
      <div className="MainContainer">

          <DashboarderSidebar />
          <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
          <main className="content-wrapper">
              <section className='plan-divvv pt-5 pb-5'>
                <div className='container'>
                  <Row className='justify-content-center mt-2'>
                    <Col md='8 justify-content-center'>
                      <div className='main-title mb-5'>Thanks for payment.</div>
                      <a className='login-btn1' href="/dashboard">Go to Dashboard</a>
                    </Col>
                  </Row>
                  {(() => {
                 
                      return (
                       <></>
                      )
                    
                  })()}

               
                </div>
              </section>
            </main>
          </div>


      <div className="modal custom-modal show" id="affilate" tabindex="-1" role="dialog" aria-labelledby="affilateTitle" aria-hidden="false">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <div className='affilate-link'>Affiliate Link</div>
              <p>It is a long established fact that a reader will be distracted by the readable content.</p>
              <h3 className='refrral-title'>Share this referral link to your friends</h3>
              <div className='link'>www.sample.info/?insect=fireman&porter=attraction#cave</div>
              <div className='ok-skip-div'>
                <a className='login-btn1' href="#">Ok</a>
                <a className='login-btn1 skip-btn' href="#">Skip</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default SuccessPayment