import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Client from '../images/client.png';
import Spyderads1 from '../images/spyderads four1.png';
import Spyderads2 from '../images/spyderads four2.png';
import Spyderads3 from '../images/spyderads four3.png';
import Spyderads4 from '../images/spyderads four4.png';
import Chromeicon from '../images/chrome.png';
import Extension from '../images/extension.png';
import banner from '../images/banner-dashbaord.png';
import shopify from '../images/shopify.png';
import longArrow from '../images/long-arrow.svg';
import slantArrow from '../images/slant-arrow.svg';
import facebook from '../images/facebook.png';
import f1 from '../images/f1.png';
import fs1 from '../images/fs1.png';
import filter1 from '../images/filter1.png';
import f2 from '../images/f2.png';
import fs2 from '../images/fs2.png';
import filter2 from '../images/filter2.png';
import f3 from '../images/f3.png';
import fs3 from '../images/fs3.png';
import Commas from '../images/commas.svg';
import filter3 from '../images/filter3.png';
import filter4 from '../images/filter4.png';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Image } from 'react-bootstrap';
function Homepage() {
  const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/pricing`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(responseData => {
                if (responseData.status) {
                    setData(responseData.data);
                } else {
                    throw new Error('Error fetching data');
                }
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

  return (
    <>
    <div className='main-nav'>
    <Header />
    <section className='banner-section'>
      <span className='circle1'></span>
      <span className='circle2'></span>
      <div className="video-main">
        <div className="promo-video">
          <div className="waves-block">
            <div className="waves wave-1"></div>
            <div className="waves wave-2"></div>
            {/* <div className="waves wave-3"></div> */}
          </div>
        </div>
        <a href="#" class="video video-popup mfp-iframe" data-lity><img src={shopify} /></a>
      </div>
      <div className="video-main video-main1">
        <div className="promo-video">
          <div className="waves-block">
            <div className="waves wave-1"></div>
            <div className="waves wave-2"></div>
          </div>
        </div>
        <a href="#" class="video video-popup mfp-iframe" data-lity><img src={facebook} /></a>
      </div>
      <Container>
        <Row className='justify-content-center'>
            <Col md='6'>
                <h1 className='banner-main-title'>Finding a winner has never been easy! </h1>
                <a target='_blank' href="https://chromewebstore.google.com/detail/spyder-ads-v10/acgjmlhlkokkgomeaignoaebahennfbp?authuser=0&hl=en&pli=1" className='add-btn'><img src={Chromeicon} />Add to Chrome</a>
            </Col>
        </Row>
        <Row className='justify-content-center'>
            <Col md='10'>
               <div className='banner-img'>
                  <img src={banner} alt='banner' />
                  <div className='view'>
                    <div className='view-title'>Views</div>
                    <div className='view-count'>22.4k</div>
                  </div>
                  <div className='view spend'>
                    <div className='view-title'>Spend</div>
                    <div className='view-count'>€20.5-€25</div>
                  </div>
               </div>
            </Col>
        </Row>
      </Container>
    </section>
    </div>

    <section className='our-top-features pt-5 pb-5'>
       <div className='container'>
          <Row className='justify-content-center mt-2'>
             <Col md='8 justify-content-center'>
                <div className='main-title mb-5'>Our Top Features</div>
             </Col>
          </Row>
          <Row className='align-items-center'>
            <Col md='6'>
              <div className='features-left mr-3'>
                <img className='f1-img' src={f1} alt="f1" />
                <img className='fs1-img' src={fs1} alt='fs1' />
              </div>
            </Col>
            <Col md='6'>
              <div className='features-right text-left'>
                  <div className='feature-upper'>
                  <img src={filter1} alt='filter1' /> <span>Filter By</span>
                  </div>
                  <p>Take advantage of our filtering system to show only Shopify stores that are actually making sales, so you'll have plenty of winning products in seconds.  </p>
                
              </div>
            </Col>
          </Row>
          <Row className='align-items-center mt-5 mb-5 center-row'>
            <Col md='6'>
              <div className='features-right text-left feature-r-l'>
                  <div className='feature-upper'>
                  <img src={filter2} alt='filter2' /> <span>Amount Spend</span>
                  </div>
                  <p>We can analyze the amount spent on advertising, and therefore its profitability. This allows us to know whether the product is profitable or not in an instant.</p>
               
              </div>
            </Col>
            <Col md='6'>
              <div className='features-left mr-3 feature-l-r'>
                <img className='f1-img' src={f2} alt="f2" />
                <img className='fs1-img' src={fs2} alt='fs2' />
              </div>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col md='6'>
              <div className='features-left mr-3'>
                <img className='f1-img' src={f3} alt="f3" />
                <img className='fs1-img' src={fs3} alt='fs3' />
              </div>
            </Col>
            <Col md='6'>
              <div className='features-right text-left'>
                  <div className='feature-upper'>
                  <img src={filter3} alt='filter3' /> <span>Productivity</span>
                  </div>
                  <p>We'll save an enormous amount of time, and  we'll find "winning products" much more easily (this is an essential point for all e-commerce and dropshippers).</p>
                  
              </div>
            </Col>
          </Row>
          <Row className='align-items-center mt-5 mb-3 center-row'>
            <Col md='6'>
              <div className='features-right text-left feature-r-l'>
                  <div className='feature-upper'>
                  <img src={filter4} alt='filter4' /> <span>Download Ads Content</span>
                  </div>
                  <p>With a single click, you can retrieve your competitors' ads and create your own creative for your store. </p>
                 
              </div>
            </Col>
            <Col md='6'>
              <div className='features-left mr-3 feature-l-r'>
                <img className='f1-img' src={f2} alt="f2" />
                <img className='fs1-img' src={fs2} alt='fs2' />
              </div>
            </Col>
          </Row>
       </div>
    </section>

    <section className='pricing-sec'>
       <div className='container'>
          <div className='row'>
             <div className='col-md-8 offset-md-2 col-sm-12'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='main-title mb-5'>Plan that Match your Needs</div>
                  </div>
                </div>
                <div className='row'>
                {data.length > 0 ? (
                    data.map(item => (
                        <div className='col-md-6' key={item.id}>
                            <div className={`match-div ${item.interval === 'year' ? 'active' : ''}`}>

                                <span className='monthly-span'>{item.name}</span>
                                <div className='price-title'>
                                {item.interval === 'year' ? (
                                        <>
                                      ${item.price} <span className='monthly-price'>${(item.price / 12).toFixed(2)}/Monthly</span>
                                           
                                        </>
                                    ) : (
                                        <>${item.price}</>
                                    )}
                                </div>
                                <hr />
                                <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                                <a className='buy-now-btn' href="#" data-id={`${item.id}`} data-interval={`${item.interval}`}>Buy Now</a>

                            </div>
                        </div>
                    ))
                ) : (
                    <p>No data available</p>
                )}
                </div>
             </div>
          </div>
       </div>
    </section>


    <section className='google-chrome-extension'>
       <div className='container chrome-container'>
          <div className='row align-items-flex-end'>
            <div className='col-md-6'>
              <div className='chrome-left text-left'>
                <a href="#" className='chrome-extension-a'>Google Chrome Extension <img src={longArrow} alt='long-arrow' /></a>
                <div className='main-title'>Find any website’s
Ads using our
Chrome Extension</div>
{/* <p className='desc-text'>It is a long established fact that a reader will be distracted by the readable. It is a long established.</p> */}
<a className='get-extension' target="_blank" href="https://chromewebstore.google.com/detail/spyder-ads-v10/acgjmlhlkokkgomeaignoaebahennfbp?authuser=0&hl=en&pli=1">Get Extension <img src={slantArrow} alt="slant-arrow" /></a>
              </div>
            </div>
            <div className='col-md-6'>
               <div className='chrome-right'>
                  <div className='downloads wave-block'>100k+ <br/><span>Downloads</span>
                  <div className="waves wave-1"></div>
                  <div className="waves wave-2"></div>
                  </div>
                  <img src={Extension} alt='extension' />
               </div>
            </div>
          </div>
       </div>
    </section>

    <section className='pricing-sec review-section'>
       <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='main-title mb-5'>Analytics</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div className='analyticsFront-img'>
                <Image src={Spyderads1} alt='img'/>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='analyticsFront-img'>
                <Image src={Spyderads2} alt='img'/>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='analyticsFront-img'>
                <Image src={Spyderads3} alt='img'/>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='analyticsFront-img'>
                <Image src={Spyderads4} alt='img'/>
              </div>
            </div>
            
          
          </div>
       </div>
    </section>

    <section className='pricing-sec review-section pt-0'>
       <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='main-title mb-5'>Frequently Asked Questions</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-10 offset-md-1'>
              <div className='faq-div'>
                <div className='faq-left'>
                How will SpyderAds help me find winners?
                </div>
                <div className='faq-right'>
                SpyderAds allows you to analyze all E-Commerce stores in record time, while showing you the amounts spent on advertising for European ads. You'll have all the information you need 
                to validate the potential of the product or store you've found.
                </div>
              </div>
              <div className='faq-div'>
                <div className='faq-left'>
                How do you estimate European ad spend? 
                </div>
                <div className='faq-right'>
                We simply use the coverage data provided by Facebook, and then, thanks to our analyses and algorithms, 
                we can estimate the amount spent on advertising.
                </div>
              </div>
              <div className='faq-div'>
                <div className='faq-left'>
                Can I download the advertising content of the ads I've found?
                </div>
                <div className='faq-right'>
                Thanks to SpyderAds, you can download any advertising content 
                in just 1 click via our "Download" button.
                </div>
              </div>
              <div className='faq-div'>
                <div className='faq-left'>
                What features do you offer with SpyderAds?
                </div>
                <div className='faq-right'>
                We currently offer 3 main features, and we plan to add many more in the near future. 
                Here are the 3 features you'll find:
                <ul className='feature-ul mt-4'>
                    <li>
                      <i class="fas fa-circle-check"></i>
                      <span>The ability to filter ad content by "Shopify Store" and "Dropshipping".</span>
                    </li>
                    <li>
                      <i class="fa-solid fa-circle-check"></i>
                      <span>The ability to view the amount spent on European ads.</span>
                    </li>
                    <li>
                      <i class="fa-solid fa-circle-check"></i>
                      <span>1-click download of ad content.</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='read-more-div'>
              <a className='buy-now-btn read-more' href="/faq">Read More</a>
              </div>
            </div>
          </div>
       </div>
    </section>
   
    <Footer />
    

    </>
  )
}

export default Homepage