import React, { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import "react-multi-carousel/lib/styles.css";
import blackCircle from '../images/blackCircle.jpg';
import FollowerAdBoxCardItem from "./FollowerAdBoxCardItem";
import { dateFromMillisecond } from '../config/Configuration';
import { Image } from 'react-bootstrap';
function FollowerAllAdBox(props) {
    const [brandImage, setBrandImage] = useState(false);
    const onErrorBrandImage = () => {
        setBrandImage(blackCircle);
    };
    // useEffect(() => {
    //     loadDefauldMedia();
    // }, []);
    useEffect(() => {
        if (props.records?.length) {
            loadDefauldMedia();
        }
    }, [props.records]);
    
    // const loadDefauldMedia = () => {
    //     //  const tempBrandImg = props?.record && props?.record?.snapshot?.page_profile_picture_url ? props?.record?.snapshot?.page_profile_picture_url : '';
       

    //     let tempBrandImg = '';
    //     let record = '';
    //     if(props?.records[0]?.record){
    //         JSON.parse(props?.records[0]?.record)
    //     }
    //     if (props?.records[0]?.ad_logo) {
    //         tempBrandImg = process.env.REACT_APP_BACKEND_BASE_URL+'/' + props?.records[0]?.ad_logo;

    //     }
    //     else if (record?.snapshot?.page_profile_picture_url) {
    //         tempBrandImg = record?.snapshot?.page_profile_picture_url;

    //     }
     
    //     setBrandImage(tempBrandImg);
    // };
    const loadDefauldMedia = () => {
        let tempBrandImg = '';
        let record = '';
        if (props?.records[0]?.record) {
            record = JSON.parse(props?.records[0]?.record);
        }
        if (props?.records[0]?.ad_logo) {
            tempBrandImg = `${process.env.REACT_APP_BACKEND_BASE_URL}/${props?.records[0]?.ad_logo}`;
        } else if (record?.snapshot?.page_profile_picture_url) {
            tempBrandImg = record?.snapshot?.page_profile_picture_url;
        } else {
            tempBrandImg = blackCircle; // Default fallback
        }
        setBrandImage(tempBrandImg);
    };
    
   
    
    return (
        <>
            <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
           
                <div className='following-companyName d-flex justify-content-between flex-wrap'>
                    <div className='d-flex gap-lg-5 gap-md-3 gap-sm-3 align-items-center flex-wrap gap-2 followingheading-Header'>
                        <div className='d-flex gap-2 align-items-center'>
                            <Image src={brandImage} onError={() => onErrorBrandImage()} alt='img' style={{ width: "50px", borderRadius: "50%", height: "50px", objectFit: "contain" }} />
                            <h3 className='mb-0' style={{ color: "#333333", fontSize: "22px", fontWeight: "400" }}>{props?.pageName}</h3>
                        </div>
                        <div className='d-flex gap-1 flex-wrap'>
                            <p className='mb-0' style={{ color: "#333" }}>{props?.records?.length} new ad  |</p>
                            <p className='mb-0' style={{ color: "#333" }}> {dateFromMillisecond(props?.records[0]?.startDate)}</p>
                        </div>
                    </div>
                    <div className='viewunfollow-btn d-flex gap-2 align-items-center'>
                    <button 
                        className='greenColor-btn' 
                        onClick={() => (window.location.href = '/following')}>
                        Go Back
                        </button>

                        </div>
                </div>
                <div className='row'>
                    {props.records.map((ad) => {
                        const record = JSON.parse(ad.record);

                        return (

                            <FollowerAdBoxCardItem
                                ad={ad}
                                record={record}
                            />

                        )
                    })

                    }
                </div>
            
     

        </>
    )
}

export default FollowerAllAdBox