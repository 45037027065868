import React from 'react';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import PlanSubscribeChecker from '../components/PlanSubscribeChecker';
import CompanyLogo from '../images/amazon.png'
import ProductImg from '../images/following3.png'
import { Image } from 'react-bootstrap';


function NewFollowing() {
    return (
        <>
            <PlanSubscribeChecker />
            <DashboarderHeader />
            <div className="MainContainer">
                <DashboarderSidebar />

                <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
                <main className="content-wrapper">
                    <div className='following-companyName d-flex justify-content-between flex-wrap'>
                        <div className='d-flex gap-lg-5 gap-md-3 gap-sm-3 align-items-center flex-wrap gap-2 followingheading-Header'>
                            <div className='d-flex gap-2 align-items-center'>
                                <Image src={CompanyLogo} alt='img' style={{ width: "50px", height: "50px", objectFit: "contain" }} />
                                <h3 className='mb-0' style={{ color: "#333333", fontSize: "22px", fontWeight: "400" }}>Amazon</h3>
                            </div>
                            <div className='d-flex gap-1 flex-wrap'>
                                <p className='mb-0' style={{ color: "#333" }}>60 new ads  |</p>
                                <p className='mb-0' style={{ color: "#333" }}>Updated Apr 10, 2024</p>
                            </div>
                        </div>
                        <div className='viewunfollow-btn d-flex gap-2 align-items-center'>
                            <button className='outline-greenColor-btn'>View More</button>
                            <button className='greenColor-btn'>Unfollow</button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
                            <div className='dayView-btn gap-2 d-flex justify-content-between flex-wrap'>
                                <button className='greenColor-btn'>8.20M Views</button>
                                <button className='blackColor-btn'>€20.5-€25</button>
                                <button className='outline-greenColor-btn'>12.41€/ Day </button>
                            </div>
                            <div className='following-productimg'>
                                <Image src={ProductImg} alt='img' />
                            </div>
                            <div className='following-productData'>
                                <p style={{ fontSize: "12px", color: "#7E7E7E" }}>It is a long established fact that a reader
                                    will be distracted by the readable.It is a long established.</p>
                                <button className='shadow-btn w-100 mb-2'>See Profile</button>
                                <button className='greenColor-btn w-100' style={{ padding: "10px" }}>See Ad</button>
                            </div>
                        </div>
                    </div>
                </main>
                
            </div> 


        </>
    )
}

export default NewFollowing