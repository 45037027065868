import './App.css';
import './css/style.css';
import './css/home.css';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Dashboard from './pages/Dashboard';
import FAQ from './pages/FAQ';
import Privacy from './pages/Privacy';

import Following from './pages/Following';
import AllFollowing from './pages/AllFollowing';
import ForgotPassword from './pages/ForgotPassword';
import Homepage from './pages/Homepage';
import Learn from './pages/Learn';
import Login from './pages/Login';
import SecureLogin from './pages/SecureLogin';
import MyAds from './pages/MyAds';
import Otp from './pages/Otp';
import Pricing from './pages/Pricing';
import ResetPassword from './pages/ResetPassword';
import SignUp from './pages/SignUp';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Subscribe from './pages/Subscribe';
import Profile from './pages/Profile';
import EditProfile from './pages/EditProfile';
import ChangePassword from './pages/ChangePassword';
import GeneralAffilateLink from './pages/GeneralAffilateLink';
import PlanHistory from './pages/PlanHistory';
import SuccessPayment from './pages/SuccessPayment';
import BankAccount from './pages/BankAccount';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute'; 
import Stripe from './pages/Stripe'; 
import NewFollowing from './pages/NewFollowing';


function App() {
  return (
    <div className="App">
      <ToastContainer position="top-right" />
      {/* <Homepage />
    
      <Login /> */}
      <BrowserRouter>
      <Routes>
          <Route path="/homepage" element={<Homepage />} />
          <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
          <Route path="/dev-login" element={<PublicRoute><SecureLogin /></PublicRoute>} />
          <Route path="/signup" element={<PublicRoute><SignUp /></PublicRoute>} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/resetpassword/:id" element={<ResetPassword />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/learn" element={<Learn />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/stripe" element={<Stripe />} />
          <Route path="/pricing" element={<Pricing />} />

          {/* Protected Routes */}
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/my-ads" element={<PrivateRoute><MyAds /></PrivateRoute>} />
          <Route path="/following" element={<PrivateRoute><Following /></PrivateRoute>} />
          <Route path="/new-following" element={<PrivateRoute><NewFollowing /></PrivateRoute>} />
          <Route path="/all-following/:id" element={<PrivateRoute><AllFollowing /></PrivateRoute>} />

          <Route path="/subscribe" element={<PrivateRoute><Subscribe /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/edit-profile" element={<PrivateRoute><EditProfile /></PrivateRoute>} />
          <Route path="/change-password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
          <Route path="/general-affilate-link" element={<PrivateRoute><GeneralAffilateLink /></PrivateRoute>} />
          <Route path="/bank-account" element={<PrivateRoute><BankAccount /></PrivateRoute>} />
          <Route path="/plan-history" element={<PrivateRoute><PlanHistory /></PrivateRoute>} />

          <Route path="/success-payment" element={<PrivateRoute><SuccessPayment /></PrivateRoute>} />


          {/* Redirect root to dashboard */}
          <Route path="/" element={<Navigate to="/dashboard" />} />
        </Routes>

      </BrowserRouter>
      </div>
  );
}

export default App;