import { FOLLOWED_AD } from '../actions/actionsTypes';

const initialState = {
   
};

export default function(state = initialState, action)  {
    switch (action.type) {
        case FOLLOWED_AD:
            return {...state,FollowedAds:action.payload.data}
        default:
            return state;
    }
}

