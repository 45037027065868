import React, { useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import Simg from '../images/no-image-257.jpg';
import { Image } from 'react-bootstrap';
import { checkImpressionFunc } from '../config/fbAdImpression';

function FollowerAdBoxCardItem(props) {
    const maxLength = 100;
    const [mediaImage, setMediaImage] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [impressionData, setImpressionData] = useState([]);
    const record = props?.record;
    useEffect(() => {
        loadDefauldMedia();

    }, []);
    const handleCheckSpend = async () => {
        const data = await checkImpressionFunc(record);
        setImpressionData(data);
    }
    const toggleReadMore = () => {
        setIsExpanded(!isExpanded);
    };
    const loadDefauldMedia = () => {
        let tempBrandImg = '';
        if (props.ad.ad_logo) {
            tempBrandImg = process.env.REACT_APP_BACKEND_BASE_URL + '/' + props.ad.ad_logo;

        }
        else if (props.record.snapshot.page_profile_picture_url) {
            tempBrandImg = props.record.snapshot.page_profile_picture_url;

        }
        let tempMediaImg = '';

        if (props.ad.ad_image) {
            tempMediaImg = process.env.REACT_APP_BACKEND_BASE_URL + '/' + props.ad.ad_image;

        }
        else if (props.ad.cardResizedImageUrl) {
            tempMediaImg = props.ad.cardResizedImageUrl;

        }

        setMediaImage(tempMediaImg);
    };
    const onErrorMediaImage = () => {
        setMediaImage(Simg);
    };
   
    return (
        <>

            <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
                <div className='following-productDataTop d-flex mt-3 gap-3'>
            <button className='outline-greenColor-btn w-100' onClick={() => window.open(props.record && props.record.snapshot ? props.record.snapshot.page_profile_uri : '', '_blank')}>See Profile</button>
            <button className='blackColor-btn w-100' onClick={() => window.open(props.record && props.record.adArchiveID ? 'https://www.facebook.com/ads/library/?id=' + props.record.adArchiveID : '', '_blank')} style={{ padding: "10px" }}>See Ad</button></div>
               
                <div className='following-productimg'>
                    <Image src={mediaImage} alt='img' onError={() => onErrorMediaImage()} />
                </div>
                <div className='following-productData'>
                    <p style={{ fontSize: "12px", color: "#7E7E7E" }}> {props.ad.cardBody && (
                                            <>
                                            {isExpanded || props.ad.cardBody.length <= maxLength
                                                ? props.ad.cardBody
                                                : `${props.ad.cardBody.substring(0, maxLength)}...`}
                                            
                                            {props.ad.cardBody.length > maxLength && (
                                                <button className="readMore-btn" onClick={toggleReadMore}>
                                                {isExpanded ? 'Read Less' : 'Read More'}
                                                </button>
                                            )}
                                            </>
                                        )}</p>


<div className='dayView-btn gap-2 d-flex justify-content-between flex-wrap mt-0 p-0'>

                    {Object.keys(impressionData).length == 0 ? <button type='button' onClick={handleCheckSpend} class="greenColor-btn views w-100" >Check Spend</button> : ''}
                {Object.keys(impressionData).length > 0 ? <>
                    <button className='greenColor-btn'>{impressionData['views'] ? impressionData['views'] : 0} Views</button>
                    <button className='blackColor-btn'>€{impressionData['spend'] ? impressionData['spend'] : 0}</button>
                    <button className='outline-greenColor-btn'>{impressionData['day'] ? impressionData['day'] : 0}€/ Days</button>
                </> : ''}
                </div>


                   
                </div>
            
            </div>
            
        </>
    )
}

export default FollowerAdBoxCardItem