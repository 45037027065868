

import axios from '../../../api/axios';


import { FETCH_AD, MY_AD, FOLLOWED_AD } from './actionsTypes';
import { startLoading, stopLoading } from './actionsTypes';

const token = JSON.parse(localStorage.getItem('token'));
const user_id = JSON.parse(localStorage.getItem('user_id'));


//export const fetchAd = () => (dispatch) => {
// export const fetchAd = () => async (dispatch) => {
//     console.log('fetchAdfetchAdfetchAd')
//     dispatch(startLoading());
//     fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboardAds/all`)
//         .then((res) => res.json())
//         .catch((err) =>
//             console.log('err')
//             /*  fetch("db.json")
//                .then((res) => res.json())
//                .then((data) => data.products) */
               
//         )
//         .then((data) => {
//             dispatch({ type: FETCH_AD, payload: data });
//             dispatch(stopLoading());

//         });
// };

export const fetchAd = () => async (dispatch) => {
    console.log('Fetching Ads...');
    dispatch(startLoading());

   
    if (!token) {
        console.warn('Token is missing. Fetch request will not include Authorization header.');
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/dashboardAds/all`, {
            method: 'GET',
            headers: {
                ...(token && { "Authorization": `Bearer ${token}` }),
                "Content-Type": "application/json", 
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        dispatch({ type: FETCH_AD, payload: data });
    } catch (error) {
        console.error('Error fetching ads:', error);
    } finally {
        dispatch(stopLoading());
    }
};
export const myAd = () => async (dispatch) => {
   
    dispatch(startLoading());
    const config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };

    const bodyParameters = {
        id: user_id
    };
    axios
        //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
        .post("/myAds", bodyParameters, config)
        .then((responseData) => {
            if (responseData.status) {
                dispatch({ type: MY_AD, payload: responseData.data });
            } else {
                throw new Error('Error fetching data');
            }
            dispatch(stopLoading());
            //   setLoader(false);
        })
        .catch((err) => {
            //   setLoader(false);
        });

};
export const followedAd = () => async (dispatch) => {
    dispatch(startLoading());

   
    if (!token) {
        console.warn('Token is missing. Fetch request will not include Authorization header.');
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/followingAds/all`, {
            method: 'GET',
            headers: {
                ...(token && { "Authorization": `Bearer ${token}` }),
                "Content-Type": "application/json", 
            }
        });
     
        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        dispatch({ type: FOLLOWED_AD, payload: data });
       
    } catch (error) {
        console.error('Error fetching ads:', error);
    } finally {
        dispatch(stopLoading());
    }
};





