import React, { useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useNavigate, useLocation } from 'react-router-dom';
import D1 from '../images/dashboard.png';
import D2 from '../images/adds.png';
import D3 from '../images/following.png';
import D4 from '../images/subscribe.png';
import D5 from '../images/Inactive.svg';
import Logout from '../images/logout.svg';
import google_chrome_gray from '../images/chromenew.png';

function UserDashboardSidebar() {
    const navigate = useNavigate();
    const location = useLocation();

    const user = JSON.parse(localStorage.getItem('user'));

    const handleLogout = () => {
        localStorage.removeItem('user');
        navigate('/login');
    };

    const [isActive, setIsActive] = useState(true); // Default to true

    useEffect(() => {
        // Apply margin-applied class to the content-wrapper when the sidebar is active by default
        const contentWrapper = document.querySelector('.content-wrapper');
        if (isActive && contentWrapper) {
            contentWrapper.classList.add('margin-applied');
        }
    }, [isActive]); // Run when isActive changes

    const toggleSidebar = () => {
        setIsActive((prev) => !prev);
        const contentWrapper = document.querySelector('.content-wrapper');
        if (contentWrapper) {
            contentWrapper.classList.toggle('margin-applied');
        }
    };

    const getActiveClass = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    return (
        <>
            <button
                className="toggle-sidebar-btn"
                onClick={toggleSidebar}
                aria-label="Toggle Sidebar"
            >
                ☰
            </button>
            <div
                className={`sidebar-content position-fixed sidebar ${
                    isActive ? 'active' : ''
                }`}
                id="sidebar"
            >
                <div className="list-group rounded-0 sidebar-left">
                    <a href="/dashboard" className={getActiveClass('/dashboard')}>
                        <img src={D1} alt="Dashboard" className="sidebarIcon" />
                    </a>
                    <a href="/my-ads" className={getActiveClass('/my-ads')}>
                        <img src={D2} alt="My Ads" className="sidebarIcon" />
                    </a>
                    <a href="/following" className={getActiveClass('/following')}>
                        <img src={D3} alt="Following" className="sidebarIcon" />
                    </a>
                    <a href="/subscribe" className={getActiveClass('/subscribe')}>
                        <img src={D4} alt="Subscribe" className="sidebarIcon" />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://chromewebstore.google.com/detail/spyder-ads-v10/acgjmlhlkokkgomeaignoaebahennfbp?authuser=0&hl=en&pli=1"
                        className={getActiveClass('/chrome')}
                    >
                        <img
                            src={google_chrome_gray}
                            alt="Chrome Extension"
                            className="sidebarIcon"
                        />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://spyderads.circle.so/join?invitation_token=23cf6e06d817872e77152dacd29efb
01561686db-9fc2e1c5-d66c-419a-809a-aa0c19953cd0"
                        className={getActiveClass('/circle')}
                    >
                        <img src={D5} alt="Circle Community" className="sidebarIcon" />
                    </a>
                    <a
                        className={`logout ${getActiveClass('/logout')}`}
                        onClick={handleLogout}
                        href="#"
                    >
                        <img src={Logout} alt="Logout" className="sidebarIcon" />
                    </a>
                </div>
            </div>
        </>
    );
}

export default UserDashboardSidebar;
