import React, { useState } from 'react';
import Blogo from '../images/black-logo.png';
import Pro from '../images/pro.png';
import D1 from '../images/d1.svg';
import D2 from '../images/d2.svg';
import D3 from '../images/d3.svg';
import D4 from '../images/d4.svg';
import Logout from '../images/logout.svg';
import P1 from '../images/p1.png';
import P2 from '../images/p2.png';
import P3 from '../images/p3.png';
import P4 from '../images/p4.png';
import P1c from '../images/p1c.png';
import P2c from '../images/p2c.png';
import P3c from '../images/p3c.png';
import P4c from '../images/p4c.png';
import Card1 from '../images/card1.png';
import Card2 from '../images/card2.png';
import Card3 from '../images/card3.png';
import Card4 from '../images/card4.png';
import CVC from '../images/cvc.png';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import UserProfileSidebar from '../components/UserProfileSidebar';

function BankAccount() {
  const [isActive, setIsActive] = useState(false);

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  return (
    <>
     <DashboarderHeader />
      <div className="MainContainer">

        <DashboarderSidebar />
        <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
        <main className="content-wrapper">
           <div className='profile-div'>
           <UserProfileSidebar />
             <div className='login-inner'>
               <h1 class="login-title">Bank Account</h1>
               <p class="desc-text">It is a long established fact that a reader will be distracted by the readable content.</p>
               <div className='profile-inner edit-profile-div change-password'>
                <div className='profile-inputs'>
                  <form>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='login-input bank-account'>
                          <input className='custom-input' type="text" placeholder='Email' />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='login-input bank-account'>
                          <label className='profile-label'>Card Information</label>
                          <input className='custom-input' type="text" placeholder='1234 1234 1234 1234' />
                          <ul className='card-ul'>
                            <li><a href='#'><img src={Card1} alt='card1' /></a></li>
                            <li><a href='#'><img src={Card2} alt='card2' /></a></li>
                            <li><a href='#'><img src={Card3} alt='card3' /></a></li>
                            <li><a href='#'><img src={Card4} alt='card4' /></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='login-input bank-account'>
                          <input className='custom-input' type="text" placeholder='MM/YY' />
                        </div>
                      </div>
                      <div className='col-md-6'>
                      <div className='login-input bank-account'>
                          <input className='custom-input' type="text" placeholder='CVC' />
                          <ul className='card-ul cvc-ul'>
                            <li><a href='#'><img src={CVC} alt='cvc' /></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='login-input bank-account'>
                          <label className='profile-label'>Name on Card</label>
                          <input className='custom-input' type="text" placeholder='John Doe' />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className='login-input bank-account'>
                          <label className='profile-label'>Country or Region</label>
                          <select className='custom-input'>
                            <option>United States</option>
                            <option>United States</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <button className='login-btn1' type='submit'>Submit</button>
                  </form>
                 </div>
               </div>
             </div>
           </div>
          </main>
        </div>
    

    </>
  )
}

export default BankAccount