import React, { useEffect, useState } from "react";
import Blogo from '../images/black-logo.png';
import Pro from '../images/pro.png';
import D1 from '../images/d1.svg';
import D2 from '../images/d2.svg';
import D3 from '../images/d3.svg';
import D4 from '../images/d4.svg';
import Logout from '../images/logout.svg';
import Edit from '../images/Edit.png';
import ProfileImg from '../images/profile-img.png';
import Camera from '../images/Camera.png';
import Email from '../images/email.svg';
import User from '../images/Profile.svg';
import DashboarderHeader from '../components/UserDashboarderHeader';
import DashboarderSidebar from '../components/UserDashboardSidebar';
import UserProfileSidebar from '../components/UserProfileSidebar';
import axios from '../api/axios';
import { toast } from "react-toastify";


function EditProfile() {
  const [isActive, setIsActive] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loginEmail, setLoginEmail] = useState('');

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  
  const token = JSON.parse(localStorage.getItem('token'));
//alert(token);
  useEffect(() => {
    getProfile();


}, []);

const getProfile = () => {
  //  setLoader(true);

//alert('dd');
    const config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };

    const bodyParameters = {
        id: 10
    };
    axios
        //  .get("/api/v1/get-profile", {},{ headers: {"Authorization" : `Bearer ${token}`} })
        .post("/user/profile", bodyParameters, config)
        .then((res) => {
            console.log('res.result');
            console.log(res.data.data);
            setFirstName(res.data.data.name);
//setLastName(res.data.data.last_name);
            setLoginEmail(res.data.data.email);
         //   setLoader(false);
        })
        .catch((err) => {
         //   setLoader(false);
        });
};


function handleFirstNameInput(event) {
  setFirstName(event.target.value)
};


const validateRegForm = async () => {
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


  if (firstName == '') {
      toast.warning('Please enter your first name..');

  }
 
  else if (loginEmail == '') {
      toast.warning('Please enter your email.');

  }
  else if (regex.test(loginEmail) === false) {

      toast.warning('Please enter your valid email.');

  }

  else {

      const postRequest = {
          firstName: firstName,
         


      }


      const config = { headers: { 'Authorization': 'Bearer ' + token } };

      console.log('postRequest');
      console.log(postRequest);

      axios
          .post("/user/update", postRequest, config)
          .then((res) => {
              if (res.status === true) {
                  toast.success(res.data.message);
                  // window.location.href = "/compose";

                  // props.insert(res.data.data);
              } else {
                  toast.success(res.data.message);
              }
          })
          .catch((err) => {
              console.log(err);
              if (err) toast.error("Something went wrong.");
          });
  }
};


  return (
    <>
    <DashboarderHeader />
      <div className="MainContainer">

        <DashboarderSidebar />
        <div className="w-100 vh-100 position-fixed overlay d-none" id="sidebar-overlay"></div>
        <main className="content-wrapper">
           <div className='profile-div'>
           <UserProfileSidebar />
             <div className='login-inner'>
               <div className='edit-profile'>
                {/*  <a href='#'><img src={Edit} alt='edit' />Edit Profile</a> */}
               </div>
               <h1 class="login-title">Profile</h1>
               <p class="desc-text">It isw a long established fact that a reader will be distracted by the readable content.</p>
               <div className='profile-inner edit-profile-div'>
                {/* <div className='profile-img'>
                   <img className='profile-img' src={ProfileImg} alt='profile-img' />
                   <a className='camera-img' href="#"><img src={Camera} alt='camera' /></a>
                </div> */}
                <div className='profile-inputs'>
                {/* <div className='contact-right login-inner'> */}
                  {/* <div className='send-message'>Send a Message</div> */}
                  <form>
                    <div className='login-input'>
                      <input className='custom-input' value={firstName} type="text" placeholder='John'  onChange={handleFirstNameInput}  />
                      <img className='input-img' src={User} alt='user' />
                    </div>
                    {/* <div className='login-input'>
                      <input className='custom-input' value={lastName} type="text" placeholder='Doe' />
                      <img className='input-img' src={User} alt='user' />
                    </div> */}
                    <div className='login-input'>
                      <input className='custom-input' value={loginEmail} type="text" placeholder='demo@admin.com' />
                      <img className='input-img' src={Email} alt='email' />
                    </div>
                    <button className='login-btn1'                             onClick={() => validateRegForm()}
 type='button'>Submit</button>
                  </form>
                 </div>
                {/* </div> */}
               </div>
             </div>
           </div>
          </main>
        </div>
    

    </>
  )
}

export default EditProfile